import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';
import { Customer } from 'emr-ng-shared';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { environment } from 'environments/environment';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

@Component({
    selector: 'app-select-customer',
    templateUrl: 'select-customer.component.html',
    styleUrls: ['select-customer.component.css']
})
export class SelectCustomerComponent implements OnInit, OnDestroy {
    customerList$: Observable<IListInfo<Customer>>;
    customerSelected: Customer;
    public selectedCustomer;
    public isBrowserRefreshed;
    selectCustomerSubscription: Subscription;
    constructor(
        private custSvc: CustomersService,
        private router: Router,
        private authService: AuthService
    ) {
        // const request: GetUserSettingRequest = {
        //     Section: 'Settings', IsGlobal: true,
        //     Key: 'SelectedCustomerId', SettingType: UserSettingsType.Int
        // };
        // this.userSettingsSvc.getUserSettingsLoader(request).subscribe(k => {
        //     debugger;
        // });
        this.customerList$ = this.custSvc.customerList$.pipe(
            tap((n) => {
                if (n && n.list && n.list.length > 0) {
                    const requestedParams = this.authService.getRequestedParams();
                    if (requestedParams) {
                        this.selectedCustomer = n.list.find(k => k.WebsiteId?.toLowerCase() === requestedParams.customer?.toLowerCase())?.CustomerId;
                        if (!this.selectedCustomer) {
                            this.authService.setRequestedParams(null);
                        }
                        else {
                            this.onCustomerSelected(Number(this.selectedCustomer));
                        }
                    } else {
                        this.isBrowserRefreshed = localStorage.getItem(environment.isBrowerRefreshed);
                        if (this.isBrowserRefreshed === '1') {
                            this.selectedCustomer = JSON.parse(localStorage.getItem(environment.selectedCustomer));

                            if (this.selectedCustomer) {
                                this.onCustomerSelected(Number(this.selectedCustomer));
                            }
                            localStorage.setItem(environment.isBrowerRefreshed, '0');
                        }
                    }
                }
            })
        );
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.selectCustomerSubscription?.unsubscribe();
    }

    onCustomerSelected(customerId: number) {
        // const request: SetUserSettingRequest = {
        //     Section: 'Settings', IsGlobal: true,
        //     Key: 'SelectedCustomerId', SettingType: UserSettingsType.Int,
        //     Value: customerId.toString()
        // };
        // this.userSettingsSvc.saveUserSettings(request).subscribe(k => { });
        this.selectCustomer(
            this,
            customerId,
            this.onSelectCustomerSuccess,
            this.onSelectCustomerFailure
        );
    }

    private selectCustomer(
        component: SelectCustomerComponent,
        customerId: number,
        successCallback,
        failureCallback) {
        const sub = this.custSvc.selectedContext$.subscribe(a => {
            this.customerSelected = a == null ? null : a.customer;
        });

        if (this.customerSelected != null && this.customerSelected.CustomerId === customerId) {
            sub.unsubscribe();
            localStorage.setItem(environment.selectedCustomer, customerId.toString());
            component.router.navigate([this.authService.getDefaultRoute()]);
        } else {
            this.selectCustomerSubscription = this.custSvc.selectCustomer(customerId).pipe(take(1)).subscribe(b => {
                this.custSvc.isSelected$.pipe(
                    take(1),
                    switchMap(n => this.custSvc.mapViewPreRequisites(n))
                ).subscribe((a) => {
                    if (a) {
                        this.custSvc.onCustomerChange(b);
                        successCallback(component); //jelax OR-2497
                    } else {
                        failureCallback();
                    }
                    sub.unsubscribe();
                });
            });
        }
    }

    private onSelectCustomerSuccess(component: SelectCustomerComponent) {
        if (!component || !component.router) { return; }
        component.router.navigate([component.authService.getDefaultRoute()]);
    }

    private onSelectCustomerFailure() { }
}





